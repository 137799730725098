import React from "react";

const educationContent = [
  {
    year: "2005",
    degree: "Microsoft Certified Professional",
    institute: "Microsoft Egypt",
    details: ` Installing, Configuring and Administering Microsoft products and servers`,
  },
  {
    year: "1997-2004",
    degree: "BACHELOR DEGREE ",
    institute: "Modern Academy Maadi",
    details: ` Subjects i studied in the first year:
    
   Programming and Usage of Computers I,
   Mathematics I,
   Digital Systems,
   Accounting,
   Management and Organisation,
   English I,
   Programming and Usage of Computers II,
   Mathematics II and Statistics,
   Computer Architecture,
   Algebra and Geometry,
   Economics,
   English II. (please contact me for the full list of studied subjects and/or the official graduation certificate)
    `,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
