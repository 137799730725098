import React from "react";

const experienceContent = [
  {
    year: "   2019 - 2023",
    position: "Administrator Assistant",
    compnayName: "Radiant Enterprise Pty. Ltd.",
    details: `
•	Setup and Administer of Mail (Postfix and Dovecot), Web (Apache) and database (MySQL) servers.
•	Developed a Factory status web application to track the status of each job according to manufacturing process and generate daily, weekly reports.
•	Setup computers in workstations.
•	Network issues troubleshooting and fixing.
•	Schedule jobs using Microsoft Excel (Doors and Frames).
•	Paperwork Filing.
•	Provide Training for new employees.
•	Developed an Inventory system to keep track of hardware products sold as addons like door handles and locks.
•	Co-Ordinate with team of developers from overseas to help develop an application (php) to cover the functionality of all factory and administrative operations.
•	Deployment of invoicing system.
•	Mobile App Development using React Native Framework.
•	Troubleshooting and Maintenance.
 `,
  },
  {
    year: "2018 - 2019",
    position: "I.T Administrator / Mobile App Developer",
    compnayName: "Trimmy's Logistics",
    details: `
•	Administration
•	Deployment of invoicing system 
•	Mobile App Development using React Native Framework
•	Troubleshooting and Maintenance
`,
  },
  {
    year: "2017 - 2018",
    position: "Assistant Manager",
    compnayName: "Australian Access Hire Pty. Ltd.",
    details: `
•	Stock Taking.
•	Develop various internal tools to ease the daily operations.
•	Generate daily reports.
•	Networking.
•	Troubleshooting and Maintenance.
    `,
  },
  {
    year: "2011 - 2016",
    position: "IT Manager",
    compnayName: "Sphinx Cabs Pty. Ltd.",
    details: `
•  Develop a PHP Form Possessor for auditing paperwork for Drivers, Vehicles and other Company Information.
•  Maintain the Daily Communications.
•  Build and administer the internal network for the Company.
•  Providing the suitable solution to ease and automate the everyday needs.
•  Follow up periodically the maintenance for the network.
devices and computers.
•  Design and implement a database containing all of the company activities (Event Manager).
    `,
  },
  {
    year: "2008 - 2009",
    position: "Programmer (Cotract Based)",
    compnayName: "Avanser Pty. Ltd.",
    details: `
•  Build the Database using MySQL.
•  Develop PHP scripts to create a provisioning system (portal).
•  Maintain SQL and Perl Queries for Reporting and Billing Clients.
•  Manage Backups Periodically.
•  Fast respond to any upcoming issue or failure.
•  Suggest any updates in the system that can boost the company's portfolio 
•  Communicate with managers and provide reports and information as requested
    `,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
